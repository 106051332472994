import styled from 'styled-components';
import { Flex } from '@components/Flex';
import { Text } from '@components/typography';
import { CTALink } from '@components/CTALink';
import { JoykitColor } from '@components/themes';
import { themeColor, themeSpace } from '@utils/styledTheme.util';

export const PhotoCreditWrap = styled(Flex)`
  padding: ${themeSpace(5)};
  width: auto;
  z-index: 3;
`;

export const PhotoCreditLink = styled(CTALink)<{ leftIconVariant?: boolean; hoverColor?: JoykitColor; activeColor?: JoykitColor }>`
  span {
    display: flex;
    flex-direction: ${({ leftIconVariant }) => (leftIconVariant ? 'row' : 'row-reverse')};
    align-items: center;
    opacity: ${({ hoverColor }) => (hoverColor ? null : '0.8')};
    transition: 0.2s;
    &:hover {
      opacity: ${({ hoverColor }) => (hoverColor ? null : '1')};
      color: ${({ hoverColor }) => (hoverColor ? themeColor(hoverColor) : null)};
    }
    &:active {
      color: ${({ activeColor }) => (activeColor ? themeColor(activeColor) : null)};
    }
  }
`;

export const TextContent = styled(Text)`
  font-size: 12px;
  line-height: 17px;
`;
