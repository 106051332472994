import React from 'react';
import styled from 'styled-components';
import { system, compose, margin, flex, get, ConfigStyle, ResponsiveValue, MarcomSpacingProps } from 'styled-system';
import { SpacingStackProps } from './SpacingStack';
import { createShouldForwardProp } from '@styled-system/should-forward-prop';
import { Theme } from '@components/themes';

const orientationStyleFn = system({
  vertical: {
    property: 'flexDirection',
    transform: value => {
      return value ? 'column' : 'row';
    },
  },
});

const inlineStyleFn = system({
  inline: {
    property: 'display',
    transform: shouldRenderAsInline => {
      return shouldRenderAsInline ? 'inline-flex' : 'flex';
    },
  },
});

// Got this from styled-system
const isNumber = (n: number) => typeof n === 'number' && !isNaN(n);

const getSpacing = (n: number, scale: unknown) => {
  if (!isNumber(n)) {
    return get(scale, n, n);
  }

  const isNegative = n < 0;
  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return isNegative ? '-' + value : value;
  }
  return value * (isNegative ? -1 : 1);
};

interface StyledSystemProps {
  vertical: boolean;
}

const spacingStyleFn = system({
  spacing: {
    property: 'margin',
    scale: 'space',
    transform: (value: number, scale: unknown, props: StyledSystemProps) => {
      const rawValue = getSpacing(value, scale);
      return props.vertical ? `0 0 ${rawValue} 0` : `0 0 0 ${rawValue}`;
    },
  } as ConfigStyle,
});

const composedResponsiveFunctions = compose(margin, flex, orientationStyleFn, inlineStyleFn);

interface RootProps {
  vertical: boolean;
  inline: ResponsiveValue<boolean, Theme>;
  spacing: MarcomSpacingProps['spacing'];
  shouldForwardProp: unknown;
}

const shouldForwardProp = createShouldForwardProp([...(composedResponsiveFunctions.propNames || []), 'orientation', 'spacing', 'inline']);
const Root = ({ vertical, inline, spacing, shouldForwardProp, ...restProps }: RootProps) => <div {...restProps} />;

export const SpacingStackWrapper = styled(Root).attrs({ shouldForwardProp })<SpacingStackProps>`
  ${composedResponsiveFunctions}

  > *:not(:${props => (props.vertical ? 'last' : 'first')}-child) {
    ${spacingStyleFn}
  }
`;
