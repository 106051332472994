import React, { useEffect, useState } from 'react';
import { SECTION_ID, VIDEO_ASSETS } from './ContactCollectorSection.constants';
import {
  CollectAddressButton,
  EyebrowWrapper,
  LottieContainer,
  StyledSection,
  StyledTextPairing,
  TextContainer,
  LearnMoreButton,
  ButtonWrapper,
} from './ContactCollectorSection.styles';
import { NewFlag } from '@components/NewFlag';
import { homePageData } from '@data-ts/pages/home';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { VimeoVideo } from '@components/VimeoVideo';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';

const { eyeBrow, title, body, linkTextPrimary, linkPrimary, linkTextSecondary, linkSecondary } = homePageData.contactCollector;

export const ContactCollectorSection = () => {
  const isMobile = useThemeMediaQuery('below_sm');
  const [videoAssets, setVideoAssets] = useState(VIDEO_ASSETS.mobile);
  useEffect(() => setVideoAssets(isMobile ? VIDEO_ASSETS.mobile : VIDEO_ASSETS.desktop), [isMobile]);
  return (
    <StyledSection as="section" id={SECTION_ID} marginX={[0, 6]} marginY={6}>
      <TextContainer paddingY={[8, 0]}>
        <LottieContainer flexDirection="column" justifyContent={['flex-start', 'center']} alignItems={['center', 'flex-start']}>
          <EyebrowWrapper>
            <NewFlag
              flagColor="white"
              flagBackgroundColor="accentBlue4"
              belowSm={{ flagColor: 'white', flagBackgroundColor: 'accentBlue4' }}
              title={eyeBrow}
              titleColor="accentBlue4"
            />
          </EyebrowWrapper>
          <StyledTextPairing
            titleVariant="calloutHed40Bold"
            titleColor="mono1"
            titleTagName="h3"
            title={title}
            body={body}
            bodyVariant="dek24"
            bodyColor="mono1"
            marginBottom={6}
            alignment={['center', 'left']}
            textAlign={['center', 'left']}
          />
          <ButtonWrapper>
            <CollectAddressButton id="collectAddressBtn" forwardedAs="a" href={linkPrimary} variant="secondary">
              {linkTextPrimary}
            </CollectAddressButton>
            <LearnMoreButton id="learnMoreBtn" forwardedAs="a" href={linkSecondary} hasChevron>
              {linkTextSecondary}
            </LearnMoreButton>
          </ButtonWrapper>
        </LottieContainer>
      </TextContainer>
      <LazyLoadWrapper>
        <VimeoVideo
          title={title}
          embedUrl={videoAssets.url}
          posterUrl={videoAssets.poster}
          autoPlay
          background
          muted
          controls={false}
          loop
          autoPause={false}
          cover
          posterOptimization="default"
          aspectRatio={0.385}
          alt="contact-collector"
        />
      </LazyLoadWrapper>
    </StyledSection>
  );
};
