import { styled } from '@styledComponents';
import { themeSpace, themeMediaQuery, themeColor } from '@utils/styledTheme.util';
import { CloudImage } from '@components/CloudImage';
import { TextPairing } from '@components/TextPairing';
import { Columns } from '@components/Columns';
import { Flex } from '@components/Flex';
import { VideoPlayer } from '@components/VideoPlayer';

export const StyledSection = styled.div`
  height: 100vh;
  min-height: var(--hero-min-height);
  position: relative;
  z-index: 1;
  contain: strict;
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const HeroVideoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
`;

export const SlurpeeBgStrip = styled(CloudImage)`
  z-index: 3;
`;

export const Step2TextWrapper = styled.div`
  position: absolute;
  bottom: 23%;
  left: 50%;
  transform: translate(-50%, 500px);
  max-width: 580px;
  ${themeMediaQuery('below_sm')} {
    right: 0px;
    width: 92vw;
    z-index: 1;
    bottom: 21vh;
    display: none;
  }
  @media (max-width: 375px) {
    height: 90vh;
  }
`;

const lottieBgWidth = 1620;
const lottieBgHeight = 1080;
const lottieBgWhRatioStr = `${lottieBgWidth} / ${lottieBgHeight}`;
const lottieBgWhRatio = lottieBgWidth / lottieBgHeight;
const lottieWhRatio = 1620 / 709;
const lottieAnimationToBgHRatio = lottieBgWhRatio / lottieWhRatio;

export const HeroBodymovinWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const HeroLottieContainer = styled.div`
  display: flex;
  width: calc(100vh * ${lottieBgWhRatio});
  height: calc(100vh * ${lottieAnimationToBgHRatio});
  @media (min-aspect-ratio: ${lottieBgWhRatioStr}) {
    width: 100vw;
    height: calc(100vw / ${lottieWhRatio});
  }
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
`;

export const HeroVideoWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  overflow: hidden;
  > video {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
  }
  #homepage-hero-video-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: #000;
    z-index: 3;
    opacity: 0.5;
  }
  > img {
    width: calc(100vh * ${lottieBgWhRatio});
    height: 100vh;
    @media (min-aspect-ratio: ${lottieBgWhRatioStr}) {
      width: 100vw;
      height: calc(100vw / ${lottieBgWhRatio});
    }
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
  }
  .home-hero-lottie-element {
    opacity: 0;
    z-index: 4;
  }
`;

export const HeroTextPairing = styled(TextPairing)<{ id: string }>`
  max-width: 670px;
  margin-bottom: ${themeSpace(9)};
  .text-pairing-title {
    max-width: 516px;
    margin-bottom: ${themeSpace(5)};
    ${themeMediaQuery('below_sm')} {
      max-width: 278px;
    }
    ${themeMediaQuery('xl')} {
      max-width: 628px;
    }
  }
  .text-pairing-body {
    max-width: 410px;
    ${themeMediaQuery('below_sm')} {
      max-width: 305px;
    }
    ${themeMediaQuery('xl')} {
      max-width: 486px;
    }
  }
  ${themeMediaQuery('below_sm')} {
    margin-bottom: ${themeSpace(8)};
  }
  @media (max-width: 320px) {
    margin-bottom: ${themeSpace(5)};
  }
`;

export const Step1TextBlockWrapper = styled(Columns)`
  position: relative;
  height: 100%;
  z-index: 1;
`;

export const ButtonsContainer = styled(Flex)`
  max-width: 620px;
  justify-content: center;
  > *:not(:first-child) {
    margin-left: ${themeSpace(10)};
  }
  ${themeMediaQuery('below_sm')} {
    max-width: 100%;
    > *:not(:first-child) {
      margin-left: ${themeSpace(5)};
    }
  }
`;

export const uiMagicXAxisShift = '30vw';
export const uiMagicYAxisShift = '30vh';
export const initialScale = 1.5;

export const UIMagicWrapper = styled.div`
  background-color: ${themeColor('yellow1')};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  > img,
  > .svg-wrapper {
    opacity: 0;
    position: absolute;
    transform-origin: center center;
    will-change: transform;
    max-width: 30%;
    max-height: 30%;
    &.ui-magic-img-top {
      top: 0;
      transform: translate(0, -${uiMagicYAxisShift}) scale(${initialScale});
    }
    &.ui-magic-img-top-right {
      right: 0;
      top: 0;
      transform: translate(${uiMagicXAxisShift}, -${uiMagicYAxisShift}) scale(${initialScale});
    }
    &.ui-magic-img-right {
      right: 0;
      transform: translate(${uiMagicXAxisShift}, 0) scale(${initialScale});
    }
    &.ui-magic-img-bottom-right {
      right: 0;
      bottom: 0;
      transform: translate(${uiMagicXAxisShift}, ${uiMagicYAxisShift}) scale(${initialScale});
    }
    &.ui-magic-img-bottom {
      bottom: 0;
      transform: translate(0, ${uiMagicYAxisShift}) scale(${initialScale});
    }
    &.ui-magic-img-bottom-left {
      left: 0;
      bottom: 0;
      transform: translate(-${uiMagicXAxisShift}, ${uiMagicYAxisShift}) scale(${initialScale});
    }
    &.ui-magic-img-left {
      left: 0;
      transform: translate(-${uiMagicXAxisShift}, 0) scale(${initialScale});
    }
    &.ui-magic-img-top-left {
      left: 0;
      top: 0;
      transform: translate(-${uiMagicXAxisShift}, -${uiMagicYAxisShift}) scale(${initialScale});
    }
  }
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const MobBoxContainer = styled.div`
  width: 100%;
  height: 92vh;
  position: relative;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${themeMediaQuery('sm')} {
    display: none;
  }
  @media (max-width: 375px) {
    height: 90vh;
  }
`;

export const HeroVideo = styled(VideoPlayer)`
  position: absolute;
  height: 100%;
`;

export const OverlayDiv = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000;
  opacity: 0.5;
`;

export const TextWrapper = styled(Columns)`
  width: 100%;
  height: 100%;
  position: absolute;
  ${themeMediaQuery('below_sm')} {
    margin-top: 2rem;
    padding-left: 0rem;
    padding-right: 0rem;
    > div {
      margin-left: 0rem;
      margin-right: 0rem;
    }
  }
`;

export const FlexContainer = styled(Flex)`
  height: 100%;
  justify-content: center;
`;

export const ColumnContent = styled(Columns.Column)`
  height: 100%;
  width: 100%;
`;

export const WaveImgContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: -3px;
  z-index: 3;
  img {
    margin-bottom: -3px;
  }
`;

export const DesktopBottomWaveImage = styled(CloudImage)`
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const MobileBottomWaveImage = styled(CloudImage)`
  ${themeMediaQuery('sm')} {
    display: none;
  }
`;
