import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Flex } from '@components/Flex';
import { TextPairing } from '@components/TextPairing';
import { Text } from '@components/typography';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import styled from 'styled-components';
import {
  HEIGHT,
  HEIGHT_MOBILE,
  HEIGHT_MOBILE_MIN,
  HEIGHT_TABLET,
  HEIGHT_TABLET_MIN,
  WIDTH_BODY,
  WIDTH_BODY_MOBILE,
  WIDTH_BODY_XL,
  WIDTH_TITLE,
  WIDTH_TITLE_MOBILE,
  WIDTH_TITLE_XL,
} from './ContactCollectorSection.constants';
import { CTALink } from '@components/CTALink';

export const StyledSection = styled(Flex)`
  height: ${HEIGHT};
  position: relative;
  ${themeMediaQuery('below_sm')} {
    height: ${HEIGHT_MOBILE};
    min-height: ${HEIGHT_MOBILE_MIN};
    justify-content: center;
  }
  ${themeMediaQuery('tablet')} {
    height: ${HEIGHT_TABLET};
    min-height: ${HEIGHT_TABLET_MIN};
    img {
      object-position: bottom;
    }
    iframe {
      padding-bottom: 50vh;
    }
  }
`;

export const TextContainer = styled(Flex)`
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: flex-start;
  ${themeMediaQuery('sm')} {
    margin-top: 0;
    align-items: center;
  }
`;

export const LottieContainer = styled(Flex)`
  padding: 0px ${themeSpace(6)};
  width: 100%;
  min-height: 100%;
  ${themeMediaQuery('sm')} {
    width: fit-content;
    transform: scale(0.8);
  }
  ${themeMediaQuery('md')} {
    transform: scale(1);
    padding: 0px ${themeSpace(12)};
  }
`;

export const EyebrowWrapper = styled(Flex)`
  align-items: flex-end;
  ${themeMediaQuery('below_sm')} {
    flex-direction: column;
    row-gap: ${themeSpace(4)};
    align-items: center;
  }
`;

export const StyledText = styled(Text)`
  position: relative;
  bottom: ${themeSpace(5)};
`;

export const StyledTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: ${WIDTH_TITLE_MOBILE};
    margin-bottom: ${themeSpace(6)};
  }
  .text-pairing-body {
    max-width: ${WIDTH_BODY_MOBILE};
  }
  ${themeMediaQuery('sm')} {
    .text-pairing-title {
      max-width: ${WIDTH_TITLE};
    }
    .text-pairing-body {
      max-width: ${WIDTH_BODY};
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: ${WIDTH_TITLE_XL};
    }
    .text-pairing-body {
      max-width: ${WIDTH_BODY_XL};
    }
  }
`;

export const CollectAddressButton = styled(Button)`
  height: ${themeSpace(8)};
  padding: 0 ${themeSpace(5)};
  background: transparent;
  color: ${themeColor('mono1')};
  border: 2px solid ${themeColor('mono1')};
  display: flex;
  align-items: center;
  ${themeMediaQuery('sm')} {
    height: ${themeSpace(9)};
    padding: 0 ${themeSpace(6)};
  }
`;

export const LearnMoreButton = styled(CTALink)`
  display: flex;
  align-items: center;
  color: ${themeColor('mono1')};
  ${themeMediaQuery('sm')} {
    font-size: 20px;
  }
`;

export const ButtonWrapper = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${themeSpace(6)};
  ${themeMediaQuery('sm')} {
    flex-direction: row;
    gap: calc(${themeSpace(7)} + 3px);
  }
`;
