import React, { useLayoutEffect, useRef } from 'react';
import { Box } from '@components/Box';
import { gsap, Linear } from 'gsap';
import { useIsMounted } from '@hooks/useIsMounted';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import ScrollMagic from 'scrollmagic';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { homePageData } from '@data-ts/pages/home';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import {
  GetStarted,
  LivestreamTextPairing,
  AnimationImageWrapper,
  GradientWrap,
  WaterfallWrapper,
  StyledSectionContent,
  LivestreamTextWrapper,
  CurtainContainer,
} from './WaterfallCouple.styles';
import { useLottieAnimation } from '@hooks/useLottieAnimation';
import { PhotoCredit } from '@components/PhotoCredit';
import { getRootUrl } from '@utils/urlHelpers.util';

const waterfallData = homePageData.waterfallData;

export const WaterfallCouple: React.FC = () => {
  const scrollController = useScrollController();
  const sectionRef = useRef<HTMLDivElement>(null);
  const waterfallCoupleRef = useRef<HTMLDivElement>(null);
  const isMounted = useIsMounted();
  const { animationRef, loadAnimation } = useLottieAnimation(
    {
      path: cloudAssetUrl('home/closing-statement/bodymovin/data.json'), // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: false, // Optional
      name: 'livestream section',
      rendererSettings: {
        progressiveLoad: false,
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    { containerRef: waterfallCoupleRef, speed: 0.5 }
  );

  useLayoutEffect(() => {
    let textScene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let lottieScene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let curtainScene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let livestreamTimeline: ReturnType<typeof gsap.timeline>;
    let animationTimeline: ReturnType<typeof gsap.timeline>;
    const sectionEl = sectionRef.current;
    if (!scrollController || !isMounted || !sectionEl) return;

    const initTimeline = () => {
      if (scrollController) {
        livestreamTimeline = gsap.timeline({ paused: true });
        animationTimeline = gsap.timeline({ paused: true });
        livestreamTimeline.add('start');
        livestreamTimeline.fromTo(
          '#waterfallTextWrapper',
          {
            opacity: 0,
            position: 'fixed',
            top: '45vh',
            bottom: 'unset',
            display: 'none',
          },
          {
            display: 'flex',
            opacity: 1,
            duration: 3,
          },
          'start+=32'
        );
        livestreamTimeline.to(
          '#waterfallTextWrapper',
          {
            position: 'absolute',
            top: 'calc(100% - 500px)',
            duration: 0,
          },
          '+=32'
        );
        livestreamTimeline.fromTo('#waterfallText', { scale: 1.6 }, { scale: 1, duration: 3 }, 'start+=32');

        animationTimeline.to(
          { frame: 0 },
          1,
          {
            frame: 1,
            onUpdate: function() {
              animationRef.current?.goToAndStop(Math.round(this.progress() * 15), true);
            },
            ease: Linear.easeNone,
          },
          0
        );
      }
    };

    const startScrollAnimation = () => {
      // TODO: Add curtain animation
      // curtainScene = new ScrollMagic.Scene({
      //   triggerElement: sectionEl,
      //   triggerHook: 'onLeave',
      //   duration: '100%',
      // })
      //   .setPin('#waterfallCurtain')
      //   .addTo(scrollController);

      textScene = new ScrollMagic.Scene({
        triggerElement: sectionEl,
        triggerHook: 'onEnter',
        duration: window.innerHeight / 2 + sectionEl.clientHeight - 500,
      })
        .addTo(scrollController)
        .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
          livestreamTimeline?.progress(event.progress);
        });

      lottieScene = new ScrollMagic.Scene({
        triggerElement: sectionEl,
        triggerHook: 'onEnter',
        duration: window.innerHeight + sectionEl.clientHeight,
      })
        .addTo(scrollController)
        .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
          // animationTimeline.progress(event.progress);
          animationTimeline.progress(event.scrollDirection === 'FORWARD' ? event.progress : 1 - event.progress);
        });
    };
    initTimeline();
    startScrollAnimation();
    const handleResize = () => {
      if (textScene) {
        textScene.duration(window.innerHeight / 2 + sectionEl.clientHeight - 500);
      }
      if (lottieScene) {
        lottieScene.duration(window.innerHeight + sectionEl.clientHeight);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (livestreamTimeline) {
        livestreamTimeline.progress(0);
        livestreamTimeline.kill();
      }
      if (animationTimeline) {
        animationTimeline.progress(0);
        animationTimeline.kill();
      }

      if (lottieScene) {
        lottieScene.destroy(true);
      }
      if (textScene) {
        textScene.destroy(true);
      }
    };
  }, [scrollController, sectionRef, animationRef, isMounted]);

  return (
    <WaterfallWrapper as="section" ref={sectionRef} backgroundColor="purple6" id="Waterfall_CTA">
      <StyledSectionContent id="waterfallSectionContent">
        <GradientWrap />
        <LivestreamTextWrapper id="waterfallTextWrapper">
          <LivestreamTextPairing variant="hed96Break" color="yellow1" tagName="h2" id="waterfallText">
            {waterfallData.title}
          </LivestreamTextPairing>
          <Box id="waterfallButton">
            <GetStarted id={waterfallData.id} variant="darkGetStartedBtn" forwardedAs={'a'} href={getRootUrl('/createwedding')}>
              {waterfallData.ctaBtn}
            </GetStarted>
          </Box>
        </LivestreamTextWrapper>
        <PhotoCredit color="yellow3" id="photoCreditHomeWaterFall" />
        <LazyLoadWrapper beforeLoad={loadAnimation}>
          <div />
        </LazyLoadWrapper>
        <AnimationImageWrapper style={{ width: '100%' }} ref={waterfallCoupleRef} />
      </StyledSectionContent>
      <CurtainContainer id="waterfallCurtain" />
    </WaterfallWrapper>
  );
};
