/* eslint-disable jsx-a11y/media-has-caption */
import { addError } from '@utils/logger';
import React, { useCallback, useEffect, useRef } from 'react';

type VideoPlayerProps = React.HTMLProps<HTMLVideoElement>;

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleError = useCallback(
    error => {
      addError(new Error(error), {
        src,
        type: 'MediaError',
      });
    },
    [src]
  );

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('error', handleError);
    }

    return () => {
      videoRef?.current?.removeEventListener('error', handleError);
    };
  }, [handleError]);

  return <video ref={videoRef} src={src} {...props} />;
};
