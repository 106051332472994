import React, { useState } from 'react';
import { SECTION_ID } from './RoomBlockSection.constants';
import {
  ExploreButton,
  EyebrowWrapper,
  LottieContainer,
  StyledSection,
  StyledText,
  StyledTextPairing,
  TextContainer,
} from './RoomBlockSection.styles';
import { NewFlag } from '@components/NewFlag';
import { homePageData } from '@data-ts/pages/home';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';

const { eyeBrow, title, body, linkText, link } = homePageData.roomBlock;

export const RoomBlockSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <StyledSection as="section" id={SECTION_ID} marginX={[0, 6]} marginY={6} isVisible={isVisible}>
      <LazyLoadWrapper beforeLoad={() => setIsVisible(true)}>
        <div />
      </LazyLoadWrapper>
      <TextContainer paddingY={[8, 0]}>
        <LottieContainer flexDirection="column" justifyContent={['flex-start', 'center']} alignItems={['center', 'flex-start']}>
          <EyebrowWrapper>
            <NewFlag
              flagColor="white"
              flagBackgroundColor="accentBlue4"
              belowSm={{ flagColor: 'white', flagBackgroundColor: 'accentBlue4' }}
            />
            <StyledText tagName="p" variant="sectionEyeBrow24" color="accentBlue4">
              {eyeBrow}
            </StyledText>
          </EyebrowWrapper>
          <StyledTextPairing
            titleVariant="calloutHed40Bold"
            titleColor="mono1"
            titleTagName="h3"
            title={title}
            body={body}
            bodyVariant="dek24"
            bodyColor="mono1"
            marginBottom={6}
            alignment={['center', 'left']}
            textAlign={['center', 'left']}
          />
          <ExploreButton id="homeRoomBlockBtn" forwardedAs="a" href={link} variant="secondary">
            {linkText}
          </ExploreButton>
        </LottieContainer>
      </TextContainer>
    </StyledSection>
  );
};
