import styled from 'styled-components';
import { Flex } from '@components/Flex';
import { Box } from '@components/Box';
import { CloudImage } from '@components/CloudImage';
import { Columns } from '@components/Columns';
import { themeSpace, themeMediaQuery } from '@utils/styledTheme.util';

// Main wrapper of home-newsroom styled tag
export const MainWrapper = styled(Box)`
  margin: ${themeSpace(0)} auto;
`;

// Content wrapper of home-newsroom styled tag
export const ContentWrapper = styled(Box)`
  margin: ${themeSpace(0)} auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Box Image wrapper of home-newsroom styled tag
export const ImageWapper = styled.div`
  text-align: center;
  padding-top: ${themeSpace(10)};
  padding-bottom: ${themeSpace(8)};
  ${themeMediaQuery('below_sm')} {
    padding-top: ${themeSpace(7)};
    padding-bottom: ${themeSpace(7)};
  }
`;

// Quote or message wrapper styled tag
export const QuoteWrapper = styled('div')`
  text-align: center;
  margin: ${themeSpace(0)} auto;
  margin: 0 ${themeSpace(11)} ${themeSpace(10)};
  max-width: 663px;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    margin: ${themeSpace(0)} auto;
    margin-bottom: 1rem;
  }
`;

// Logo Wrapper styled tag
export const LogoWrapper = styled(Flex)`
  margin: ${themeSpace(0)} ${themeSpace(8)} auto;
  max-width: 100%;
  overflow: auto;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    > div {
      max-width: 50%;
    }
  }
`;

export const ColumnWrapper = styled(Columns.Column)`
  width: 100%;
`;

export const ColumnsWrapper = styled(Columns)`
  max-width: 100%;
  ${themeMediaQuery('below_sm')} {
    max-width: unset;
  }
`;

// Type defined for custom props with readonly property
type CloudVariant = Readonly<{
  padding: string;
}>;

// Custom cloud s3 image wrapper styled tag
export const CustomCloudImage = styled(CloudImage).attrs(({ padding }: CloudVariant) => ({
  padding,
}))`
  padding: ${props => props.padding};
  width: 230px;
  ${themeMediaQuery('below_sm')} {
    width: 100%;
    padding: 15px 10px;
  }
`;
