import * as React from 'react';

import { CloudImage } from '@components/CloudImage';
import { Text } from '@components/typography';
import {
  MainWrapper,
  ContentWrapper,
  QuoteWrapper,
  LogoWrapper,
  CustomCloudImage,
  ImageWapper,
  ColumnWrapper,
  ColumnsWrapper,
} from './HomeNewsSection.styles';

import { homeNewsRoomData } from '@data-ts/pages/home-newsroom';

const firstLogo = [
  'home/pr-logos/vogue_logo.svg',
  'home/pr-logos/new-york-times_logo.svg',
  'home/pr-logos/refinery29_logo.svg',
  'home/pr-logos/huffpost_logo.svg',
];
const lastLogo = [
  'home/pr-logos/techcrunch_logo.svg',
  'home/pr-logos/bustle_logo.svg',
  'home/pr-logos/mashable_logo.svg',
  'home/pr-logos/brides_logo.svg',
];

export const HomeNewsSection = () => {
  return (
    <MainWrapper as="section" backgroundColor="yellow1" id="Press">
      <ColumnsWrapper>
        <ColumnWrapper>
          <ContentWrapper paddingY={[10, 10, 12]} paddingX={[5, 5, 11]}>
            <QuoteWrapper>
              <Text tagName="h2" variant="hed56" color="purple5">
                {homeNewsRoomData.quote}
              </Text>
              <ImageWapper>
                <CloudImage src={homeNewsRoomData.quoteImageSrc} alt="" optimization="default" />
              </ImageWapper>
            </QuoteWrapper>
            <LogoWrapper justifyContent="space-between" alignItems="center">
              {firstLogo.map((data, i) => (
                <div key={i}>
                  <CustomCloudImage src={data} alt="" padding="15px 24px" optimization="default" />
                </div>
              ))}
            </LogoWrapper>
            <LogoWrapper justifyContent="space-between" alignItems="center">
              {lastLogo.map((data, k) => (
                <div key={k}>
                  <CustomCloudImage src={data} alt="" padding="15px 24px" optimization="default" />
                </div>
              ))}
            </LogoWrapper>
          </ContentWrapper>
        </ColumnWrapper>
      </ColumnsWrapper>
    </MainWrapper>
  );
};
