import React from 'react';
import { SpacingStackWrapper } from './SpacingStack.styles';
import { MarcomFlexProps, MarcomMarginProps, MarcomSpacingProps, ResponsiveValue } from 'styled-system';
import { Theme } from '@components/themes';

export interface SpacingStackProps
  extends MarcomMarginProps,
    MarcomFlexProps,
    MarcomSpacingProps,
    Readonly<{
      /**
       * Whether the children should be laid vertically or horizontally.
       *
       * @default true
       */
      vertical?: boolean;

      /**
       * Whether the container should behave like a inline element rather than a block element.
       *
       * @default false
       */
      inline?: ResponsiveValue<boolean, Theme>;
    }> {}

const SpacingStack: React.FC<SpacingStackProps> = ({ vertical = true, inline = false, spacing = 2, ...restProps }) => {
  return <SpacingStackWrapper {...restProps} spacing={spacing} vertical={vertical} inline={inline} />;
};

SpacingStack.displayName = 'SpacingStack';

export { SpacingStack };
