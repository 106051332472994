import { styled, keyframes } from '@styledComponents';
import { Text } from '@components/typography';
import { Button } from '@components/Button';
import { Box } from '@components/Box';
import { themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import { TOP_BAR_COLLAPSED_HEIGHT_DESKTOP } from 'src/layout/components/Header/Header.styles';

const blinker = keyframes`
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

export const LivestreamTextWrapper = styled(Box)`
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LivestreamTextPairing = styled(Text)`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 ${themeSpace(6)};
`;

export const GetStarted = styled(Button)`
  margin-top: 50px;
  z-index: 9;
`;

export const AnimationContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`;

export const AnimationImageWrapper = styled(Box)`
  height: 2228px;
  width: 100%;
  filter: brightness(0.7);

  ${themeMediaQuery('below_sm')} {
    max-height: 150vh;
  }
`;

export const GradientWrap = styled(Box)`
  height: 600px;
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  left: 0;
  background: linear-gradient(180deg, #fff2e3 10%, rgba(255, 245, 229, 0) 100%);
`;

export const WaterfallWrapper = styled(Box)`
  height: fit-content;
  position: relative;
  // TODO: Add curtain effect
  // margin-bottom: -100vh;
  overflow: hidden;
  z-index: 2;
`;

export const CurtainContainer = styled(Box)`
  width: 100%;
  height: calc(100vh - ${TOP_BAR_COLLAPSED_HEIGHT_DESKTOP});
  position: absolute;
  visibility: hidden;
  bottom: 0px;
`;

export const StyledSectionContent = styled(Box)`
  height: fit-content;
  position: relative;
  top: 0px;
  bottom: calc(-100vh + ${TOP_BAR_COLLAPSED_HEIGHT_DESKTOP});
`;
