import { Box } from '@components/Box';
import { Button } from '@components/Button';
import { Flex } from '@components/Flex';
import { TextPairing } from '@components/TextPairing';
import { Text } from '@components/typography';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { themeColor, themeMediaQuery, themeSpace } from '@utils/styledTheme.util';
import styled from 'styled-components';
import {
  BACKGROUND_IMAGE,
  HEIGHT,
  HEIGHT_MOBILE,
  HEIGHT_MOBILE_MIN,
  HEIGHT_TABLET,
  WIDTH_BODY,
  WIDTH_BODY_MOBILE,
  WIDTH_BODY_XL,
  WIDTH_TITLE,
  WIDTH_TITLE_MOBILE,
  WIDTH_TITLE_XL,
} from './RoomBlockSection.constants';

export const StyledSection = styled(Box)<{ isVisible: boolean }>`
  background: url(${({ isVisible }) => (isVisible ? cloudAssetUrl(BACKGROUND_IMAGE.desktop, { optimization: 'default' }) : '')}),
    linear-gradient(90deg, rgba(255, 255, 255, 0.6) 23.29%, rgba(255, 255, 255, 0.23) 41.39%, rgba(255, 255, 255, 0) 53.82%),
    linear-gradient(90deg, rgba(255, 255, 255, 0.6) -1.1%, rgba(255, 255, 255, 0.23) 49.03%, rgba(255, 255, 255, 0) 71.15%), lightgrey;
  height: ${HEIGHT};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  ${themeMediaQuery('below_sm')} {
    background: url(${({ isVisible }) => (isVisible ? cloudAssetUrl(BACKGROUND_IMAGE.mobile, { optimization: 'default' }) : '')}), lightgrey;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    height: ${HEIGHT_MOBILE};
    min-height: ${HEIGHT_MOBILE_MIN};
    justify-content: center;
  }
  ${themeMediaQuery('tablet')} {
    height: ${HEIGHT_TABLET};
  }
`;

export const TextContainer = styled(Flex)`
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  position: absolute;
  align-items: flex-start;
  ${themeMediaQuery('tablet')} {
    margin-top: ${themeSpace(10)};
  }
  ${themeMediaQuery('sm')} {
    margin-top: 0;
    align-items: center;
  }
`;

export const LottieContainer = styled(Flex)`
  padding: 0px ${themeSpace(6)};
  width: 100%;
  min-height: 100%;
  ${themeMediaQuery('sm')} {
    width: fit-content;
    transform: scale(0.8);
  }
  ${themeMediaQuery('md')} {
    transform: scale(1);
    padding: 0px ${themeSpace(12)};
  }
`;

export const EyebrowWrapper = styled(Flex)`
  align-items: flex-end;
  ${themeMediaQuery('below_sm')} {
    flex-direction: column;
    row-gap: ${themeSpace(4)};
    align-items: center;
  }
`;

export const StyledText = styled(Text)`
  position: relative;
  bottom: ${themeSpace(5)};
`;

export const StyledTextPairing = styled(TextPairing)`
  .text-pairing-title {
    max-width: ${WIDTH_TITLE_MOBILE};
    margin-bottom: ${themeSpace(6)};
  }
  .text-pairing-body {
    max-width: ${WIDTH_BODY_MOBILE};
  }
  ${themeMediaQuery('sm')} {
    .text-pairing-title {
      max-width: ${WIDTH_TITLE};
    }
    .text-pairing-body {
      max-width: ${WIDTH_BODY};
    }
  }
  ${themeMediaQuery('xl')} {
    .text-pairing-title {
      max-width: ${WIDTH_TITLE_XL};
    }
    .text-pairing-body {
      max-width: ${WIDTH_BODY_XL};
    }
  }
`;

export const ExploreButton = styled(Button)`
  display: flex;
  align-items: center;
  height: ${themeSpace(8)};
  padding: 0 ${themeSpace(5)};
  background: transparent;
  color: ${themeColor('mono1')};
  border: 2px solid ${themeColor('mono1')};
  ${themeMediaQuery('sm')} {
    height: ${themeSpace(9)};
    padding: 0 ${themeSpace(6)};
  }
`;
