import { keyframes, styled } from '@styledComponents';
import { Box } from '@components/Box';
import { CloudImage, PlaceholderCloudImage } from '@components/CloudImage';
import { variant } from 'styled-system';
import { themeColor, themeSpace } from '@utils/styledTheme.util';
import { Column, Columns } from '@components/Columns';
import { animationTransition } from '@utils/animationTransition';
import { CTALink } from '@components/CTALink';
import { Text } from '@components/typography';
import { PhotoCredit } from '@components/PhotoCredit';
import { themeMediaQuery } from '@utils/styledTheme.util';

export const topBarHeight = '139px';

export const StyledSection = styled(Box)`
  height: 100vh;
  position: relative;
  z-index: 1;
  contain: strict;
  ${themeMediaQuery('below_sm')} {
    display: none;
  }
`;

export const topBorderHeight = '32px';

export const ContentWrapper = styled.div`
  border-top: solid 0px ${themeColor('yellow1')};
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${themeColor('yellow1')};
  z-index: 0;
`;

export const MaskedCloudImage = styled(CloudImage)<{ maskId: string }>`
  clip-path: url(#${({ maskId }) => maskId});
`;

export const StyledColumns = styled(Columns)`
  height: 100%;
  position: relative;
  z-index: 1;
  ${themeMediaQuery('sm')} {
    min-width: 57rem;
  }
`;

export const FeaturesColumn = styled(Column)`
  height: 100%;
`;

export const rightBlockWidthRatio = 7 / 12;

export const FeatureContentColumn = styled(Column)`
  height: 100%;
`;

const FeatureBaseBFImage = styled(PlaceholderCloudImage)<{ aspectratio: number; scale?: number }>`
  width: 100%;
  min-width: calc((100vh - ${topBorderHeight}) * ${({ aspectratio }) => aspectratio});
  min-height: calc(100vw * (${rightBlockWidthRatio}) / ${({ aspectratio }) => aspectratio});
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) ${({ scale }) => (scale ? `scale(${scale})` : '')};
  transform-origin: center center;
  will-change: transform;
`;

export const FeatureBackgroundImage = styled(FeatureBaseBFImage).attrs({ className: 'home-product-feature-background' })<{
  aspectratio: number;
  isEagerLoading: boolean;
}>``;

export const RegistryFeatureBackgroundImage = styled(FeatureBaseBFImage).attrs({ className: 'home-product-feature-background' })<{
  aspectratio: number;
  isEagerLoading: boolean;
}>`
  top: 47.5%;
  min-width: calc((107vh - ${topBorderHeight}) * ${({ aspectratio }) => aspectratio});
`;

export const FeatureUiImage = styled(PlaceholderCloudImage).attrs({ className: 'home-product-feature-ui' })<{
  aspectratio: number;
  scale?: number;
}>`
  width: 90%;
  min-width: calc((100vh - ${topBorderHeight}) * ${({ aspectratio }) => aspectratio} * 0.5);
  min-height: calc(100vw * (${rightBlockWidthRatio}) / ${({ aspectratio }) => aspectratio} * 0.5);
  position: absolute;
  left: 52%;
  opacity: 0;
  top: calc(100px + 8%);
  transform: translate(-50%, 0) ${({ scale }) => (scale ? `scale(${scale})` : '')};
  transform-origin: center center;
`;

export const RegistryFeatureUiImage = styled(FeatureUiImage)`
  transform: translate(-53%, 35%) ${({ scale }) => (scale ? `scale(${scale})` : '')};
`;

export const FeatureForegroundImage = styled(FeatureBaseBFImage).attrs({ className: 'home-product-feature-foreground' })<{
  maskId?: string;
}>`
  clip-path: ${({ maskId }) => (maskId ? `url(#${maskId})` : 'none')};
  transform-origin: center bottom;
`;

export const RegistryFeatureForegroundImage = styled(FeatureForegroundImage)`
  min-width: calc((60vh - ${topBorderHeight}) * ${({ aspectratio }) => aspectratio});
  transform: translate(-50%, -50%) ${({ scale }) => (scale ? `scale(${scale})` : '')};
`;

export const FeaturesList = styled.div<{ isControlVersion: boolean }>`
  height: ${props => (props.isControlVersion ? '100%' : `calc(100% + ${topBarHeight})`)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${themeColor('yellow1')};
  > *:not(:first-child) {
    padding-top: ${themeSpace(7)};
  }
`;

export const FeatureAnimationWrapper = styled.div<{ isFirst?: boolean; isForHover?: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  will-change: transform;
  contain: strict;
  opacity: ${({ isForHover }) => (isForHover ? 0 : 1)};
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: ${({ isFirst, isForHover }) => (isFirst || isForHover ? 'translate(0px, 0px)' : 'translate(0px, 100%)')};
  }
`;

const fadeInAnimation = keyframes`
  0%{opacity: 0.3}
  0%{opacity: 0.6}
  100%{opacity: 1}
`;

export const FeatureItem = styled(CTALink).attrs({ typography: 'hed40', variant: 'homeFeature' })<{
  $isActive?: boolean;
  $isHidden?: boolean;
}>`
  color: ${({ $isActive }) => ($isActive ? themeColor('linkHomeFeatureHover') : '')};
  transition: ${animationTransition('color', 'transform')};
  ${variant({
    prop: 'typography',
    scale: 'typography',
  })};
  font-style: ${({ $isActive }) => ($isActive ? 'italic' : 'normal')};
  &:hover {
    svg {
      transform: translate(20%, 0);
    }
  }
  svg {
    animation: ${fadeInAnimation} 3s;
  }
`;

const fadeInLinkDesc = keyframes`
  0%{opacity: 0}
  0%{opacity: 0.3}
  100%{opacity: 0.7}
`;

export const LinkDescription = styled(Text)`
  opacity: 0.7;
  animation: ${fadeInLinkDesc} 3s;
  max-width: 317px;
  margin-top: ${themeSpace(5)};
  margin-bottom: ${themeSpace(5)};
  white-space: normal;
  font-style: normal;
`;

export const LinkTitle = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${themeSpace(0)};
`;

export const FeatureItemWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const AssetLink = styled(CTALink)`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const PhotoCreditContent = styled(PhotoCredit)`
  bottom: -6px !important;
  animation: ${fadeInAnimation} 3s;
`;
