export const SECTION_ID = 'Contact_Collector_Section';

export const VIDEO_ASSETS = {
  mobile: {
    url: 'https://player.vimeo.com/video/923913158?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    poster: 'home/contact-collector/cc-mobile-fallback.jpg',
  },
  desktop: {
    url: 'https://player.vimeo.com/video/923913167?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479 ',
    poster: 'home/contact-collector/cc-desktop-fallback.jpg',
  },
};

export const HEIGHT = '39.4vw';
export const HEIGHT_MOBILE = '120vh';
export const HEIGHT_MOBILE_MIN = '1074px';
export const HEIGHT_TABLET = '120vh';
export const HEIGHT_TABLET_MIN = '1428px';

export const WIDTH_TITLE = '490px';
export const WIDTH_TITLE_MOBILE = '297px';
export const WIDTH_TITLE_XL = '420px';
export const WIDTH_BODY = '504px';
export const WIDTH_BODY_MOBILE = '313px';
export const WIDTH_BODY_XL = '480px';
