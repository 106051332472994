import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ScrollMagic from 'scrollmagic';
import { gsap, Sine } from 'gsap';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import { useTheme } from '@styledComponents';
import { useIsMounted } from '@hooks/useIsMounted';

interface FeatureElements {
  wrapper: HTMLDivElement;
  container: HTMLDivElement;
  background: HTMLElement;
  ui: NodeListOf<HTMLElement>;
  foreground: HTMLElement;
}

const getFeatureElements = (wrapperRef: React.RefObject<HTMLDivElement>): FeatureElements | null => {
  const wrapper = wrapperRef.current;
  const container = wrapper?.querySelector('div');
  const background = container?.querySelector<HTMLElement>('.home-product-feature-background');
  const ui = container?.querySelectorAll<HTMLElement>('.home-product-feature-ui');
  const foreground = container?.querySelector<HTMLElement>('.home-product-feature-foreground');
  let result = null;
  if (wrapper && container && background && ui && foreground) {
    result = { wrapper, container, background, ui, foreground };
  }
  return result;
};

interface InitTimelineRefs {
  sectionRef: React.RefObject<HTMLDivElement>;
  weddingWebsiteFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  guestListFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  saveTheDatesFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  invitationsFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  smartRSVPFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  registryFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  mobileAppFeatureWrapperRef: React.RefObject<HTMLDivElement>;
  scrollDirectionRef: React.RefObject<string>;
}

const sectionsVScrollEase = Sine.easeIn;
const defaultEase = Sine.easeOut;
const prevFeatureFadeDuration = 0.05;
const sectionsVScrollDuration = 1.5;
const sectionVScrollAnimationOverlap = 0.5;
const sectionAnimationDuration = 2.5;
const sectionAnimationAfterScrollDuration = sectionAnimationDuration - sectionVScrollAnimationOverlap;
const sectionIntroAnimationDelay = 0.5;
const sectionIntroAnimationDuration = sectionAnimationDuration - sectionIntroAnimationDelay;

const createWeddingWebsiteFeatureAppearanceTimeline = (weddingWebsiteFeatureElements: FeatureElements) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  timeline.to(weddingWebsiteFeatureElements.ui, {
    opacity: 0.7,
    scale: 1.18,
    x: '-3%',
    duration: sectionAnimationDuration,
    ease: Sine.easeInOut,
  });
  return timeline;
};

const createWeddingWebsiteFeatureIntroTimeline = (weddingWebsiteFeatureElements: FeatureElements) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  timeline.to(weddingWebsiteFeatureElements.background, { scale: 0.95, duration: sectionIntroAnimationDuration }, 0);
  timeline.to(weddingWebsiteFeatureElements.foreground, { scale: 1.08, duration: sectionIntroAnimationDuration }, 0);
  return timeline;
};

const createGuestListFeatureAppearanceTimeline = (
  prevFeatureElements: FeatureElements,
  guestListFeatureElements: FeatureElements,
  withScroll: boolean,
  createSelectFeatureIndexCallback?: (prevIndex: number, nextIndex: number) => () => void
) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  if (withScroll) {
    timeline.to(prevFeatureElements.container, { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase }, 0);
    timeline.to(prevFeatureElements.container, { autoAlpha: 0, duration: prevFeatureFadeDuration }, `>-${prevFeatureFadeDuration}`);
    timeline.to(
      guestListFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    if (createSelectFeatureIndexCallback) {
      timeline.call(createSelectFeatureIndexCallback(1, 2), [], '-=0.5');
    }
  }
  timeline.to(
    guestListFeatureElements.ui[0],
    { opacity: 0.2, scale: 1.15, duration: sectionAnimationDuration },
    `-=${sectionVScrollAnimationOverlap}`
  );
  timeline.to(
    guestListFeatureElements.ui[1],
    { opacity: 0.9, scale: 1.15, duration: sectionAnimationDuration },
    `>-${sectionAnimationDuration}`
  );
  return timeline;
};

const createGuestListFeatureIntroTimeline = (guestListFeatureElements: FeatureElements) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  timeline.to(guestListFeatureElements.background, { scale: 0.94, duration: sectionIntroAnimationDuration }, 0);
  timeline.to(guestListFeatureElements.foreground, { scale: 1.12, duration: sectionIntroAnimationDuration }, 0);
  return timeline;
};

const createSaveTheDateFeatureAppearanceTimeline = (
  prevFeatureElements: FeatureElements,
  saveTheDateFeatureElements: FeatureElements,
  withScroll: boolean,
  createSelectFeatureIndexCallback?: (prevIndex: number, nextIndex: number) => () => void
) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  if (withScroll) {
    timeline.to(prevFeatureElements.container, { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase }, 0);
    timeline.to(prevFeatureElements.container, { autoAlpha: 0, duration: prevFeatureFadeDuration }, `>-${prevFeatureFadeDuration}`);
    timeline.to(
      saveTheDateFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    if (createSelectFeatureIndexCallback) {
      timeline.call(createSelectFeatureIndexCallback(2, 3), [], '-=0.5');
    }
  }
  timeline.to(
    saveTheDateFeatureElements.ui,
    { opacity: 0.9, scale: 1, duration: sectionAnimationDuration },
    `-=${sectionVScrollAnimationOverlap}`
  );
  return timeline;
};

const createSaveTheDateFeatureIntroTimeline = (saveTheDateFeatureElements: FeatureElements) => {
  const timeline = gsap.timeline({ defaults: { ease: defaultEase } });
  timeline.to(saveTheDateFeatureElements.background, { scale: 0.94, duration: sectionIntroAnimationDuration }, 0);
  timeline.to(saveTheDateFeatureElements.foreground, { scale: 1.12, duration: sectionIntroAnimationDuration }, 0);
  return timeline;
};

const initTimeline = (
  scrollController: ScrollMagic.Controller | undefined,
  {
    sectionRef,
    weddingWebsiteFeatureWrapperRef,
    guestListFeatureWrapperRef,
    saveTheDatesFeatureWrapperRef,
    invitationsFeatureWrapperRef,
    smartRSVPFeatureWrapperRef,
    registryFeatureWrapperRef,
    mobileAppFeatureWrapperRef,
    scrollDirectionRef,
  }: InitTimelineRefs,
  setSelectedFeatureIndex: (newIndex: number) => void
) => {
  let heroTimeline: ReturnType<typeof gsap.timeline> | null = null;
  const sectionEl = sectionRef.current;
  const weddingWebsiteFeatureElements = getFeatureElements(weddingWebsiteFeatureWrapperRef);
  const guestListFeatureElements = getFeatureElements(guestListFeatureWrapperRef);
  const saveTheDatesFeatureElements = getFeatureElements(saveTheDatesFeatureWrapperRef);
  const invitationsFeatureElements = getFeatureElements(invitationsFeatureWrapperRef);
  const smartRSVPFeatureElements = getFeatureElements(smartRSVPFeatureWrapperRef);
  const registryFeatureElements = getFeatureElements(registryFeatureWrapperRef);
  const mobileAppFeatureElements = getFeatureElements(mobileAppFeatureWrapperRef);
  if (
    scrollController &&
    sectionEl &&
    weddingWebsiteFeatureElements &&
    guestListFeatureElements &&
    saveTheDatesFeatureElements &&
    invitationsFeatureElements &&
    smartRSVPFeatureElements &&
    registryFeatureElements &&
    mobileAppFeatureElements
  ) {
    const createSelectFeatureIndexCallback = (prevIndex: number, nextIndex: number) => {
      return () => {
        if (scrollDirectionRef.current === 'FORWARD') {
          setSelectedFeatureIndex(nextIndex);
        } else {
          setSelectedFeatureIndex(prevIndex);
        }
      };
    };
    // Wedding website
    heroTimeline = gsap.timeline({ paused: true, defaults: { ease: defaultEase } });
    heroTimeline.add(createWeddingWebsiteFeatureAppearanceTimeline(weddingWebsiteFeatureElements));
    heroTimeline.add(createWeddingWebsiteFeatureIntroTimeline(weddingWebsiteFeatureElements), `>-${sectionIntroAnimationDuration}`);

    // Registry
    const registryFeatureAppearance = gsap.timeline({ defaults: { ease: defaultEase } });
    registryFeatureAppearance.to(
      weddingWebsiteFeatureElements.container,
      { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase },
      0
    );
    registryFeatureAppearance.to(
      weddingWebsiteFeatureElements.container,
      { autoAlpha: 0, duration: prevFeatureFadeDuration },
      `>-${prevFeatureFadeDuration}`
    );
    registryFeatureAppearance.to(
      registryFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    registryFeatureAppearance.call(createSelectFeatureIndexCallback(0, 1), [], '-=0.5');
    registryFeatureAppearance.to(
      registryFeatureElements.ui,
      { duration: sectionAnimationDuration, ease: defaultEase },
      `-=${sectionVScrollAnimationOverlap}`
    );
    heroTimeline.add(registryFeatureAppearance);
    const registryFeatureIntroAnimation = gsap.timeline({ defaults: { ease: defaultEase } });
    registryFeatureIntroAnimation.to(registryFeatureElements.background, { scale: 1.5, duration: sectionIntroAnimationDuration }, 0);
    registryFeatureIntroAnimation.from(registryFeatureElements.foreground, { scale: 1.3, duration: 0 }, 0);
    registryFeatureIntroAnimation.to(registryFeatureElements.foreground, { scale: 1, duration: sectionIntroAnimationDuration }, 0);
    registryFeatureIntroAnimation.to(
      registryFeatureElements.ui,
      { x: '-=3%', y: '-=20%', scale: 1.4, duration: sectionIntroAnimationDuration },
      0
    );
    heroTimeline.add(registryFeatureIntroAnimation, `>-${sectionIntroAnimationDuration}`);
    // Guest list
    heroTimeline.add(
      createGuestListFeatureAppearanceTimeline(registryFeatureElements, guestListFeatureElements, true, createSelectFeatureIndexCallback)
    );
    heroTimeline.add(createGuestListFeatureIntroTimeline(guestListFeatureElements), `>-${sectionIntroAnimationDuration}`);
    // Save the Dates
    heroTimeline.add(
      createSaveTheDateFeatureAppearanceTimeline(
        guestListFeatureElements,
        saveTheDatesFeatureElements,
        true,
        createSelectFeatureIndexCallback
      )
    );
    heroTimeline.add(createSaveTheDateFeatureIntroTimeline(saveTheDatesFeatureElements), `>-${sectionIntroAnimationDuration}`);
    // Invitations
    const invitationsFeatureAppearance = gsap.timeline({ defaults: { ease: defaultEase } });
    invitationsFeatureAppearance.to(
      saveTheDatesFeatureElements.container,
      { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase },
      0
    );
    invitationsFeatureAppearance.to(
      saveTheDatesFeatureElements.container,
      { autoAlpha: 0, duration: prevFeatureFadeDuration },
      `>-${prevFeatureFadeDuration}`
    );
    invitationsFeatureAppearance.to(
      invitationsFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    invitationsFeatureAppearance.call(createSelectFeatureIndexCallback(3, 4), [], '-=0.5');
    invitationsFeatureAppearance.to(
      invitationsFeatureElements.ui,
      { opacity: 1.1, duration: sectionAnimationDuration, ease: defaultEase },
      `-=${sectionVScrollAnimationOverlap}`
    );
    heroTimeline.add(invitationsFeatureAppearance);
    const invitationsFeatureIntroAnimation = gsap.timeline({ defaults: { ease: defaultEase } });
    invitationsFeatureIntroAnimation.to(invitationsFeatureElements.background, { scale: 1, duration: sectionIntroAnimationDuration }, 0);
    invitationsFeatureIntroAnimation.to(invitationsFeatureElements.foreground, { scale: 1.12, duration: sectionIntroAnimationDuration }, 0);
    invitationsFeatureIntroAnimation.to(
      invitationsFeatureElements.ui[0],
      { scale: 1.3, y: '-=10%', duration: sectionIntroAnimationDuration },
      0
    );
    invitationsFeatureIntroAnimation.to(invitationsFeatureElements.ui[1], { scale: 1, duration: sectionIntroAnimationDuration }, 0);
    heroTimeline.add(invitationsFeatureIntroAnimation, `>-${sectionIntroAnimationDuration}`);

    //smart RSVP list
    const smartRsvpFeatureAppearance = gsap.timeline({ defaults: { ease: defaultEase } });
    smartRsvpFeatureAppearance.to(
      invitationsFeatureElements.container,
      { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase },
      0
    );
    smartRsvpFeatureAppearance.to(
      invitationsFeatureElements.container,
      { autoAlpha: 0, duration: prevFeatureFadeDuration },
      `>-${prevFeatureFadeDuration}`
    );
    smartRsvpFeatureAppearance.to(
      smartRSVPFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    smartRsvpFeatureAppearance.call(createSelectFeatureIndexCallback(4, 5), [], '-=0.5');
    smartRsvpFeatureAppearance.to(
      smartRSVPFeatureElements.ui,
      { opacity: 0.9, duration: sectionAnimationDuration, ease: defaultEase },
      `-=${sectionVScrollAnimationOverlap}`
    );
    heroTimeline.add(smartRsvpFeatureAppearance);
    const smartRsvpFeatureIntroAnimation = gsap.timeline({ defaults: { ease: defaultEase } });
    smartRsvpFeatureIntroAnimation.to(smartRSVPFeatureElements.background, { scale: 1.05, duration: sectionIntroAnimationDuration }, 0);
    smartRsvpFeatureIntroAnimation.to(smartRSVPFeatureElements.foreground, { scale: 0.5, duration: sectionIntroAnimationDuration }, 0);
    smartRsvpFeatureIntroAnimation.to(
      smartRSVPFeatureElements.ui[1],
      { scale: 0.35, y: '-=10%', duration: sectionIntroAnimationDuration },
      0
    );
    smartRsvpFeatureIntroAnimation.to(smartRSVPFeatureElements.ui[1], { scale: 1, duration: sectionIntroAnimationDuration }, 0);
    heroTimeline.add(smartRsvpFeatureIntroAnimation, `>-${sectionIntroAnimationDuration}`);
    // Mobile app
    const mobileAppFeatureAppearance = gsap.timeline({ defaults: { ease: defaultEase } });
    mobileAppFeatureAppearance.to(
      smartRSVPFeatureElements.container,
      { y: '-=100%', duration: sectionsVScrollDuration, ease: sectionsVScrollEase },
      0
    );
    mobileAppFeatureAppearance.to(
      smartRSVPFeatureElements.container,
      { autoAlpha: 0, duration: prevFeatureFadeDuration },
      `>-${prevFeatureFadeDuration}`
    );
    mobileAppFeatureAppearance.to(
      mobileAppFeatureElements.container,
      {
        y: '-=100%',
        duration: sectionsVScrollDuration,
        ease: sectionsVScrollEase,
      },
      0
    );
    heroTimeline.to(mobileAppFeatureElements.ui, { opacity: 1, duration: 0, ease: defaultEase }, '>');
    mobileAppFeatureAppearance.call(createSelectFeatureIndexCallback(5, 6), [], '-=0.5');
    heroTimeline.add(mobileAppFeatureAppearance);
    heroTimeline.to(mobileAppFeatureElements.ui, { opacity: 1, duration: 0.5, ease: defaultEase }, '>');
    heroTimeline.to(
      mobileAppFeatureElements.background,
      {
        duration: sectionAnimationAfterScrollDuration + 0.2,
        ease: Sine.easeInOut,
      },
      '<-0.2'
    );
    const mobileAppAnimationMoveStart = 'mobileAppAnimationMoveStart';
    heroTimeline.addLabel(mobileAppAnimationMoveStart, `>-${sectionIntroAnimationDuration}`);
    heroTimeline.to(mobileAppFeatureElements.ui, { y: '-=200px', duration: sectionIntroAnimationDuration }, 'mobileAppAnimationMoveStart');
    heroTimeline.to(
      mobileAppFeatureElements.foreground,
      { y: '-=200px', duration: sectionIntroAnimationDuration },
      'mobileAppAnimationMoveStart'
    );
    heroTimeline.to(
      mobileAppFeatureElements.ui,
      { scale: 0.7, duration: sectionIntroAnimationDuration - 0.2, delay: 0.2 },
      'mobileAppAnimationMoveStart'
    );
    heroTimeline.to(
      mobileAppFeatureElements.foreground,
      { scale: 0.65, duration: sectionIntroAnimationDuration - 0.2, delay: 0.2 },
      'mobileAppAnimationMoveStart'
    );
  }
  return heroTimeline;
};

export const homeFeaturesAnimationDurationPercent = 840;

const hoverFadeDuration = 0.5;

export const useProductFeaturesSectionController = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  //
  const weddingWebsiteFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const guestListFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const saveTheDatesFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const invitationsFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const smartRSVPFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const registryFeatureWrapperRef = useRef<HTMLDivElement>(null);
  const mobileAppFeatureWrapperRef = useRef<HTMLDivElement>(null);
  //
  const scrollDirectionRef = useRef<string>('FORWARD');
  const refreshTimelineRef = useRef(() => {});
  const [resizeWidth, setResizeWidth] = useState<number | undefined>();
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
  const scrollController = useScrollController();
  const theme = useTheme();
  const isMounted = useIsMounted();
  const [isAppearedInViewport, setIsAppearedInViewport] = useState(false);
  const refs = useMemo(
    () => ({
      scrollDirectionRef,
      sectionRef,
      weddingWebsiteFeatureWrapperRef,
      guestListFeatureWrapperRef,
      saveTheDatesFeatureWrapperRef,
      invitationsFeatureWrapperRef,
      smartRSVPFeatureWrapperRef,
      registryFeatureWrapperRef,
      mobileAppFeatureWrapperRef,
    }),
    [
      scrollDirectionRef,
      sectionRef,
      weddingWebsiteFeatureWrapperRef,
      guestListFeatureWrapperRef,
      saveTheDatesFeatureWrapperRef,
      invitationsFeatureWrapperRef,
      smartRSVPFeatureWrapperRef,
      registryFeatureWrapperRef,
      mobileAppFeatureWrapperRef,
    ]
  );

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    const sectionEl = sectionRef.current;
    let heroTimeline = initTimeline(scrollController, refs, setSelectedFeatureIndex);
    let timeout: ReturnType<typeof setTimeout>;
    const refreshTimeline = () => {
      if (scrollController) {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
          const scrollPos = scrollController?.scrollPos() as number;
          heroTimeline?.progress(0);
          heroTimeline?.kill();
          //
          heroTimeline = initTimeline(scrollController, refs, setSelectedFeatureIndex);
          if (scene && sectionEl) {
            scene.removePin(true);
            scene.setPin(sectionEl);
            scene.update();
          }
          scrollController.scrollTo(scrollPos);
        }, 750);
      }
    };
    refreshTimelineRef.current = refreshTimeline;

    const initScene = () => {
      if (sectionEl && scrollController) {
        scene = new ScrollMagic.Scene({
          triggerElement: sectionEl,
          triggerHook: 'onLeave',
          duration: `${homeFeaturesAnimationDurationPercent}%`,
        })
          .setPin(sectionEl)
          // .addIndicators()
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            scrollDirectionRef.current = event.scrollDirection;
            if (heroTimeline) {
              heroTimeline?.progress(event.progress);
            }
          });
      }
    };
    initScene();
    //
    window.addEventListener('resize', refreshTimeline);
    const clearScene = () => {
      if (heroTimeline) {
        heroTimeline.progress(0);
        heroTimeline.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return () => {
      clearScene();
      window.removeEventListener('resize', refreshTimeline);
    };
  }, [scrollController, isMounted, theme, refs]);

  useEffect(() => {
    const innerWidth = window.innerWidth;
    if (innerWidth < 2000) {
      setResizeWidth(1500);
    }
  }, [setResizeWidth]);

  return {
    scrollController,
    sectionRef,
    weddingWebsiteFeatureWrapperRef,
    guestListFeatureWrapperRef,
    saveTheDatesFeatureWrapperRef,
    invitationsFeatureWrapperRef,
    smartRSVPFeatureWrapperRef,
    registryFeatureWrapperRef,
    mobileAppFeatureWrapperRef,
    selectedFeatureIndex,
    isAppearedInViewport,
    setIsAppearedInViewport,
    resizeWidth,
  };
};
