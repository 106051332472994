import React from 'react';
import { Button } from '@components/Button';
import { Columns } from '@components/Columns';
import { PlaceholderCloudImage } from '@components/CloudImage';
import { Flex } from '@components/Flex';
import { Text } from '@components/typography';
import { PhotoCredit } from '@components/PhotoCredit';
import { useHomeHeroSectionController } from '@sections/home/HomeHeroSection/HomeHeroSection.controller';
import {
  ButtonsContainer,
  HeroLottieContainer,
  HeroTextPairing,
  HeroVideoContainer,
  HeroVideoWrapper,
  Step1TextBlockWrapper,
  Step2TextWrapper,
  StyledSection,
  UIMagicWrapper,
  MobBoxContainer,
  HeroVideo,
  TextWrapper,
  SlurpeeBgStrip,
  FlexContainer,
  ColumnContent,
  OverlayDiv,
  WaveImgContainer,
  DesktopBottomWaveImage,
  MobileBottomWaveImage,
} from '@sections/home/HomeHeroSection/HomeHeroSection.styles';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { homePageData } from '@data-ts/pages/home';
import ReactHtmlParser from 'react-html-parser';
import { getRootUrl } from '@utils/urlHelpers.util';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { VideoPlayer } from '@components/VideoPlayer';

const heroData = homePageData.sections.hero;
const uiMagicData = heroData.uiMagic;

interface HeroVideoSectionProps {
  onHeroAnimationProgress?: (progress: number) => void;
}

export const HomeHeroSection: React.FC<HeroVideoSectionProps> = ({ onHeroAnimationProgress }) => {
  const {
    sectionRef,
    uiMagicWrapperRef,
    heroVideoContainerRef,
    heroVideoWrapperRef,
    step1TextBlockRef,
    step2TextWrapperRef,
    heroLottieRef,
    isUiAssetsLoaded,
    videoUrl,
    waveRef,
    isVisible,
    isVisibleMobile,
    setIsVisible,
    setIsVisibleMobile,
  } = useHomeHeroSectionController({
    onHeroAnimationProgress,
  });
  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <StyledSection as="section" ref={sectionRef} id="Home_Hero">
        <LazyLoadWrapper beforeLoad={() => setIsVisible(true)} threshold={0}>
          <div />
        </LazyLoadWrapper>
        <HeroVideoContainer ref={heroVideoContainerRef}>
          <HeroVideoWrapper ref={heroVideoWrapperRef}>
            <SlurpeeBgStrip
              className="home-hero-lottie-element"
              src={'home/hero/bodymovin/slurpee/bg_stripping.jpg'}
              alt="Couple in the city"
              isEagerLoading
              optimization="aggressive"
            />
            <HeroLottieContainer className="home-hero-lottie-element" ref={heroLottieRef}>
              <PhotoCredit color="white" id="photoCreditHome" />
            </HeroLottieContainer>
            <div id="homepage-hero-video-overlay" />
            {isVisible && (
              <VideoPlayer
                muted
                loop
                autoPlay
                preload="auto"
                src={videoUrl}
                poster={cloudAssetUrl('home/hero/joy-header-static.jpg', { optimization: 'aggressive' })}
                playsInline
              />
            )}
            <WaveImgContainer ref={waveRef}>
              <DesktopBottomWaveImage
                src="home/hero/wave.svg"
                alt={heroData.waveAlt}
                isEagerLoading
                width="100%"
                optimization="aggressive"
              />
            </WaveImgContainer>
          </HeroVideoWrapper>
        </HeroVideoContainer>
        <Step2TextWrapper ref={step2TextWrapperRef}>
          <Text variant="hed40" color="yellow5" tagName="h3" textAlign="center">
            Create a wedding website and
          </Text>
          <Text variant="hed40" color="yellow5" tagName="h3" textAlign="center">
            registry that is uniquely yours.
          </Text>
        </Step2TextWrapper>
        <Step1TextBlockWrapper verticalAlign="center">
          <Columns.Column width={12}>
            <Flex ref={step1TextBlockRef} flexDirection={'column'} alignItems={'center'}>
              <HeroTextPairing
                id="wedding_website_hero_text_block"
                title={ReactHtmlParser(heroData.titleV2)}
                titleVariant="hed72Hero"
                titleColor="purple1"
                body={heroData.step1ContentV2}
                bodyVariant="dek24"
                bodyColor="purple1"
                bodyTagName="h1"
                marginTop={[15, 0]}
                marginBottom={[10]}
                alignment={'center'}
              />
              <ButtonsContainer>
                <Button id="homeHeroGetStartedBtn" variant="primary" forwardedAs={'a'} href={getRootUrl('/createwedding')}>
                  {heroData.getStartedBtn}
                </Button>
                <Button id="homeHeroFindCoupledBtn" variant="darkfindACoupleBtn" forwardedAs={'a'} href="/find">
                  {heroData.findEventBtn}
                </Button>
              </ButtonsContainer>
            </Flex>
          </Columns.Column>
        </Step1TextBlockWrapper>
        <UIMagicWrapper ref={uiMagicWrapperRef}>
          <PlaceholderCloudImage
            src="home/ui-magic/colors.png"
            style={{ top: '4%', left: '58%', maxHeight: '15%' }}
            className="ui-magic-img-top"
            alt={uiMagicData.colorsAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/send-reminder.png"
            className="ui-magic-img-top"
            style={{ top: '0%', left: '25%' }}
            alt={uiMagicData.sendReminderAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/statuses.png"
            className="ui-magic-img-top-right"
            style={{ top: '12%', right: '1%', maxWidth: '12%' }}
            alt={uiMagicData.statusesAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/std.png"
            style={{ top: '8%', right: '14%' }}
            className="ui-magic-img-top-right"
            alt={uiMagicData.saveTheDateAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/hotel-booking.png"
            className="ui-magic-img-right"
            style={{ top: '42%', right: '0%' }}
            alt={uiMagicData.hotelBookingAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/registry-types.png"
            style={{ top: '40%', right: '25%', zIndex: 1 }}
            className="ui-magic-img-right"
            alt={uiMagicData.registryTypesAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/rehearsal-dinner.png"
            className="ui-magic-img-bottom-right"
            style={{ bottom: '2%', right: '1%', maxWidth: '15%' }}
            alt={uiMagicData.rehearsalDinnerAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/song-requests.png"
            className="ui-magic-img-bottom-right"
            style={{ bottom: '5%', right: '18%', maxWidth: '15%' }}
            alt={uiMagicData.songRequestsAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/table-12.png"
            style={{ bottom: '1%', right: '37.5%', maxWidth: '25%' }}
            className="ui-magic-img-bottom"
            alt={uiMagicData.weddingTableGroupAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/chloe-daniel.png"
            style={{ bottom: '-5%', left: '1%' }}
            className="ui-magic-img-bottom-left"
            alt={uiMagicData.rsvpFormAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/filter-guest.png"
            style={{ bottom: '10%', left: '18%', maxWidth: '17%' }}
            className="ui-magic-img-bottom-left"
            alt={uiMagicData.guestsFilterAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/map.png"
            className="ui-magic-img-left"
            style={{ top: '45%', left: '-10%' }}
            alt={uiMagicData.mapAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/count-summary.png"
            style={{ top: '40%', left: '25%', zIndex: 1 }}
            className="ui-magic-img-left"
            alt={uiMagicData.guestsCountSummaryAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
          <PlaceholderCloudImage
            src="home/ui-magic/rsvp-together.png"
            className="ui-magic-img-top-left"
            style={{ top: '11%', left: '2%', maxWidth: '15%' }}
            alt={uiMagicData.rsvpTogetherAlt}
            isMainSrcShown={isUiAssetsLoaded}
            optimization="aggressive"
          />
        </UIMagicWrapper>
      </StyledSection>
      <MobBoxContainer>
        <LazyLoadWrapper beforeLoad={() => setIsVisibleMobile(true)} threshold={0}>
          <div />
        </LazyLoadWrapper>
        {isVisibleMobile && (
          <HeroVideo
            muted
            loop
            autoPlay
            preload="auto"
            src={videoUrl}
            poster={cloudAssetUrl('home/hero/joy-header-static.jpg', { optimization: 'aggressive' })}
            playsInline
          />
        )}
        <OverlayDiv />
        <TextWrapper>
          <ColumnContent width={12}>
            <FlexContainer flexDirection="column" alignItems="center">
              <HeroTextPairing
                id="wedding_website_hero_text_block"
                title={ReactHtmlParser(heroData.titleV2)}
                titleVariant="hed72Hero"
                titleColor="purple1"
                body={heroData.step1ContentV2}
                bodyVariant="dek24"
                bodyColor="purple1"
                bodyTagName="h1"
                alignment={'center'}
              />
              <ButtonsContainer>
                <Button id="mobileHomeHeroGetStartedBtn" variant="darkGetStartedBtn" forwardedAs={'a'} href={getRootUrl('/createwedding')}>
                  {heroData.getStartedBtn}
                </Button>
                <Button id="mobileHomeHeroFindCoupledBtn" variant="darkfindACoupleBtn" forwardedAs={'a'} href={getRootUrl('/find')}>
                  {heroData.findEventBtn}
                </Button>
              </ButtonsContainer>
            </FlexContainer>
          </ColumnContent>
        </TextWrapper>
        <WaveImgContainer ref={waveRef}>
          <MobileBottomWaveImage
            src="home/hero/wave-mobile.svg"
            alt={heroData.waveAlt}
            isEagerLoading
            width="106%"
            optimization="aggressive"
          />
        </WaveImgContainer>
      </MobBoxContainer>
    </div>
  );
};
