import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import { useScrollController } from '@context/ScrollControllerProvider/useScrollController';
import { useTheme } from '@styledComponents';
import { useIsMounted } from '@hooks/useIsMounted';
import ScrollMagic from 'scrollmagic';
import { gsap, Sine, Linear } from 'gsap';
import { cloudAssetUrl } from '@utils/cloudAssetUrl.util';
import { uiMagicXAxisShift, uiMagicYAxisShift } from '@sections/home/HomeHeroSection/HomeHeroSection.styles';
import { useLottieAnimation } from '@hooks/useLottieAnimation';
import { useThemeMediaQuery } from '@hooks/useThemeMediaQuery';

interface Props {
  onHeroAnimationProgress?: (progress: number) => void;
}

export const homeHeroAnimationDurationPercent = 200;

export const useHomeHeroSectionController = ({ onHeroAnimationProgress }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);
  const heroVideoContainerRef = useRef<HTMLDivElement>(null);
  const heroVideoWrapperRef = useRef<HTMLDivElement>(null);
  const step1TextBlockRef = useRef<HTMLDivElement>(null);
  const step2TextWrapperRef = useRef<HTMLDivElement>(null);
  const uiMagicWrapperRef = useRef<HTMLDivElement>(null);
  const heroLottieRef = useRef<HTMLDivElement>(null);
  const getStartedButtonRef = useRef<HTMLAnchorElement>(null);
  const waveRef = useRef<HTMLDivElement>(null);
  const scrollController = useScrollController();
  const theme = useTheme();
  const [isUiAssetsLoaded, setUiAssetsLoaded] = useState(false);
  const isMounted = useIsMounted();
  const isMobileDevice = useThemeMediaQuery('below_sm');
  const { animationRef, loadAnimation } = useLottieAnimation(
    {
      path: cloudAssetUrl('home/hero/bodymovin/slurpee/halved/data.json'), // Required
      renderer: 'svg',
      loop: false,
      autoplay: true,
      name: 'homeHeroMovin',
      rendererSettings: {
        // this should be false for nicer animation
        progressiveLoad: false,
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
    { containerRef: heroLottieRef, speed: 0.3 },
    'aggressive'
  );
  const [videoUrl, setVideoUrl] = useState(
    'https://player.vimeo.com/progressive_redirect/playback/463962658/rendition/1080p/file.mp4?loc=external&signature=b3e3328422556aa3af034d4b772d2b7ef74480641bf0774ed9d41516c5c866d2'
  );

  useLayoutEffect(() => {
    let scene: InstanceType<typeof ScrollMagic.Scene> | undefined;
    let heroTimeline: ReturnType<typeof gsap.timeline>;
    const sectionEl = sectionRef.current;
    const heroVideoContainerEl = heroVideoContainerRef.current;
    const heroVideoWrapperEl = heroVideoWrapperRef.current;
    const step1TextBlockEl = step1TextBlockRef.current;
    const step2TextWrapperEl = step2TextWrapperRef.current;
    const uiMagicWrapperEl = uiMagicWrapperRef.current;
    const waveEl = waveRef.current;
    let timeout: ReturnType<typeof setTimeout>;
    const refreshTimeline = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (scrollController && isMounted && isMobileDevice) {
          const scrollPos = scrollController?.scrollPos() as number;
          if (heroTimeline) {
            heroTimeline.progress(0);
            heroTimeline.kill();
          }
          initTimeline();
          if (scene && sectionEl) {
            scene.removePin(true);
            scene.setPin(sectionEl);
            scene.update();
          }
          scrollController.scrollTo(scrollPos);
        }
      }, 300);
    };
    const initTimeline = () => {
      if (
        scrollController &&
        isMounted &&
        sectionEl &&
        heroVideoWrapperEl &&
        heroVideoContainerEl &&
        step1TextBlockEl &&
        uiMagicWrapperEl &&
        step2TextWrapperEl &&
        waveEl &&
        !isMobileDevice
      ) {
        if (scrollController) {
          heroTimeline = gsap.timeline({ paused: true, defaults: { ease: Sine.easeOut } });
          heroTimeline.to(
            waveEl,
            {
              autoAlpha: 0,
              display: 'none',
              duration: 0.2,
            },
            0
          );
          // 0.05 - is just relatively small timing to init bodymovin assets loading after user starts scrolling
          heroTimeline.call(
            () => {
              if (!animationRef.current) {
                loadAnimation();
              }
              animationRef.current?.goToAndPlay(0, true);
              setUiAssetsLoaded(true);
            },
            [],
            0.05
          );
          heroTimeline.to(
            heroVideoContainerEl,
            {
              scale: 0.325,
              left: '50%',
              top: '43%',
              duration: 1,
            },
            0
          );
          heroTimeline.to(
            heroVideoContainerEl.querySelector('video'),
            {
              duration: 0.5,
              autoAlpha: 0,
              ease: Linear.easeNone,
            },
            0
          );
          heroTimeline.to(
            heroVideoContainerEl.querySelectorAll('.home-hero-lottie-element'),
            {
              duration: 0.1,
              autoAlpha: 1,
              ease: Linear.easeNone,
            },
            0
          );
          heroTimeline.to(
            step1TextBlockEl,
            {
              autoAlpha: 0,
              y: '-=5vh',
              duration: 0.5,
            },
            0
          );
          heroTimeline.to(
            step2TextWrapperEl,
            {
              y: '-=500',
              duration: 1,
            },
            0
          );
          //
          const uiMagicDuration = 1;
          const baseUiMagicVars = {
            autoAlpha: 1,

            duration: (index: number) => uiMagicDuration - index * 0.2,
            delay: (index: number) => index * 0.2,
          };
          const uiMagicTimeline = gsap.timeline({ defaults: { ease: Sine.easeOut } });
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('img'),
            {
              scale: 1,
              delay: 0.35,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-top'),
            {
              y: `+=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-top-right'),
            {
              x: `-=${uiMagicXAxisShift}`,
              y: `+=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-right'),
            {
              x: `-=${uiMagicXAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-bottom-right'),
            {
              x: `-=${uiMagicXAxisShift}`,
              y: `-=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-bottom'),
            {
              y: `-=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-bottom-left'),
            {
              x: `+=${uiMagicXAxisShift}`,
              y: `-=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-left'),
            {
              x: `+=${uiMagicXAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          uiMagicTimeline.to(
            uiMagicWrapperEl.querySelectorAll('.ui-magic-img-top-left'),
            {
              x: `+=${uiMagicXAxisShift}`,
              y: `+=${uiMagicYAxisShift}`,
              ...baseUiMagicVars,
            },
            0
          );
          heroTimeline.add(uiMagicTimeline, 0);
        }
      }
    };

    const initScene = () => {
      if (sectionEl && isMounted && scrollController && !isMobileDevice) {
        scene = new ScrollMagic.Scene({
          triggerElement: sectionEl,
          triggerHook: 'onLeave',
          duration: `${homeHeroAnimationDurationPercent}%`,
        })
          .setPin(sectionEl)
          // .addIndicators()
          .addTo(scrollController)
          // workaround from here https://github.com/janpaepke/ScrollMagic/issues/918 until plugin for gsap 3 released https://github.com/janpaepke/ScrollMagic/pull/920 or we update it locally
          .on('progress', (event: ScrollMagic.SceneProgressEvent) => {
            heroTimeline?.progress(event.progress);
            onHeroAnimationProgress?.(event.progress);
          });
      }
    };
    initTimeline();
    initScene();
    //
    window.addEventListener('resize', refreshTimeline);
    const clearScene = () => {
      if (heroTimeline) {
        heroTimeline.progress(0);
        heroTimeline.kill();
      }
      if (scene) {
        scene.destroy(true);
      }
    };
    return () => {
      clearScene();
      window.removeEventListener('resize', refreshTimeline);
    };
  }, [scrollController, isMounted, theme, onHeroAnimationProgress, animationRef, setUiAssetsLoaded, loadAnimation, isMobileDevice]);

  useEffect(() => {
    const innerWidth = window.innerWidth;
    if (innerWidth <= 640) {
      setVideoUrl(
        'https://player.vimeo.com/progressive_redirect/playback/463962658/rendition/360p/file.mp4?loc=external&signature=cdf1a22aded89717f7fd29417031e090897977b32cb58b74c94c98008cf7b8b6'
      );
    } else if (innerWidth <= 960) {
      setVideoUrl(
        'https://player.vimeo.com/progressive_redirect/playback/463962658/rendition/540p/file.mp4?loc=external&signature=ca61c3322b406f79cf5855c4a29f3e6c1d38309c69efff8b94f500c78c2ca367'
      );
    } else if (innerWidth <= 1280) {
      setVideoUrl(
        'https://player.vimeo.com/progressive_redirect/playback/463962658/rendition/720p/file.mp4?loc=external&signature=66736d8c449f8f2bc7c1c3e639846830713e810437c6b3794744f993c4fae43c'
      );
    } else {
      setVideoUrl(
        'https://player.vimeo.com/progressive_redirect/playback/463962658/rendition/1080p/file.mp4?loc=external&signature=b3e3328422556aa3af034d4b772d2b7ef74480641bf0774ed9d41516c5c866d2'
      );
    }
  }, [setVideoUrl]);

  return {
    sectionRef,
    heroVideoContainerRef,
    heroVideoWrapperRef,
    step1TextBlockRef,
    step2TextWrapperRef,
    uiMagicWrapperRef,
    getStartedButtonRef,
    heroLottieRef,
    isUiAssetsLoaded,
    videoUrl,
    waveRef,
    isVisible,
    setIsVisible,
    isVisibleMobile,
    setIsVisibleMobile,
  };
};
