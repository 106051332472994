import React from 'react';
import {
  AssetLink,
  ContentWrapper,
  FeatureAnimationWrapper,
  FeatureBackgroundImage,
  RegistryFeatureBackgroundImage,
  FeatureForegroundImage,
  RegistryFeatureForegroundImage,
  FeatureItem,
  FeatureItemWrapper,
  FeaturesColumn,
  FeaturesList,
  FeatureUiImage,
  RegistryFeatureUiImage,
  LinkDescription,
  LinkTitle,
  PhotoCreditContent,
  rightBlockWidthRatio,
  StyledColumns,
  StyledSection,
  topBorderHeight,
} from '@sections/home/ProductFeaturesSection/ProductFeaturesSection.styles';
import { useProductFeaturesSectionController } from '@sections/home/ProductFeaturesSectionV2/ProductFeaturesSectionV2.controller';
import { Box } from '@components/Box';
import { homePageData } from '@data-ts/pages/home';
import { LazyLoadWrapper } from '@components/LazyLoadWrapper';
import { JoykitIcon } from '@components/JoykitIcon';
import { DexecureImageOptions } from '@utils/cloudAssetUrl.util';

const featureBgAspectRatio = 3000 / 2000;
const registryBgAspectRatio = 3000 / 2250;
const weddingWebsiteUiAspectRatio = 3000 / 2000;
const guestListUiAspectRatio = 3980 / 2106;
const saveTheDateUiAspectRatio = 3169 / 2771;
const smartRSVPUiAspectRatio = 3000 / 2000;
const registryUiAspectRatio = 3000 / 2019;
const mobileAppUiAspectRatio = 439 / 938;
const registryFgAspectRatio = 3000 / 1171;
const hotelUiAspectRatio = 3000 / 2500;
const hotelBgAspectRatio = 3600 / 2287;
const hotelFgAspectRatio = 3600 / 2625;

const LinksMapping = [
  '/wedding-website',
  '/registry',
  '/hotel-room-blocks',
  '/guest-list',
  '/save-the-date',
  '/wedding-invitations',
  '/online-rsvp',
  '/app',
];

const FeatureAnimationContainer = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{ isFirst?: boolean; isForHover?: boolean; selectedFeatureIndex: number }>
>(({ isFirst, isForHover, children, selectedFeatureIndex }, ref) => {
  return (
    <FeatureAnimationWrapper ref={ref} isFirst={isFirst} isForHover={isForHover}>
      <div>{children}</div>
      <AssetLink
        id={`linkAsset${selectedFeatureIndex}`}
        aria-label={LinksMapping[selectedFeatureIndex]}
        href={LinksMapping[selectedFeatureIndex]}
      />
    </FeatureAnimationWrapper>
  );
});

const sectionData = homePageData.sections.features;

export const ProductFeaturesSectionV2: React.FC = () => {
  const {
    sectionRef,
    weddingWebsiteFeatureWrapperRef,
    guestListFeatureWrapperRef,
    saveTheDatesFeatureWrapperRef,
    invitationsFeatureWrapperRef,
    smartRSVPFeatureWrapperRef,
    registryFeatureWrapperRef,
    mobileAppFeatureWrapperRef,
    hotelRoomblocksFeatureWrapperRef,
    selectedFeatureIndex,
    isAppearedInViewport,
    setIsAppearedInViewport,
    resizeWidth,
  } = useProductFeaturesSectionController();

  const featureItems = [
    {
      id: 'home-features-to-wedding-website',
      to: '/wedding-website',
      label: sectionData.weddingWebsite.listItemLabel,
      desc: 'Beautiful, free and uniquely yours, with smart features for all your planning needs.',
    },
    {
      id: 'home-features-to-registry',
      to: '/registry',
      label: sectionData.registry.listItemLabel,
      desc: 'Your All-in-One registry, from gifts, to zero-fee cash funds, to honeymoon experiences.',
    },
    {
      id: 'home-features-to-hotel-room-blocks',
      to: '/hotel-room-blocks',
      label: sectionData.hotelRoomBlocks.listItemLabel,
      desc: 'Unlock discounted group hotel rates for your guests without using your credit card.',
    },
    {
      id: 'home-features-to-guest-list',
      to: '/guest-list',
      label: sectionData.guestList.listItemLabel,
      desc: 'Organize and track every detail of your nuptials from your free planning hub.',
    },
    {
      id: 'home-features-save-the-dates',
      to: '/save-the-date',
      label: sectionData.saveTheDates.listItemLabel,
      desc: 'Hundreds of design options to announce your wedding in style. Send for free online or order matching prints.',
    },
    {
      id: 'home-features-to-invitations',
      to: '/wedding-invitations',
      label: sectionData.invitations.listItemLabel,
      desc: 'Design the perfect invitation for your big day. Send for free online or order matching prints.',
    },
    {
      id: 'home-features-to-smart-rsvp',
      to: '/online-rsvp',
      label: sectionData.smartRSVP.listItemLabel,
      desc: 'Ask any question and track every answer with powerful and free Smart RSVP.',
    },
    {
      id: 'home-features-to-guest-app',
      to: '/app',
      label: sectionData.guestApp.listItemLabel,
      onShow: () => {},
      onHide: () => {},
      desc: 'Every detail at your fingertips with a free mobile app to match your website.',
    },
  ];
  const featureImageProps: DexecureImageOptions & { isMainSrcShown: boolean } = {
    resizeWidth,
    isMainSrcShown: isAppearedInViewport,
    optimization: 'default',
  };

  return (
    <StyledSection ref={sectionRef} as="section" backgroundColor={'yellow1'} id="Routers">
      <ContentWrapper>
        <Box
          style={{
            right: 0,
            width: `calc(${rightBlockWidthRatio * 100}%)`,
            height: `calc(100% - ${topBorderHeight})`,
            position: 'absolute',
            overflow: 'hidden',
            zIndex: 2,
          }}
        >
          <LazyLoadWrapper beforeLoad={() => setIsAppearedInViewport(true)}>
            <div />
          </LazyLoadWrapper>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={weddingWebsiteFeatureWrapperRef} isFirst={true}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-wedding-website/router-ww-bg.jpg'}
              alt=""
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureUiImage
              src={'home/router-sections/router-wedding-website/router-ww-ui.png'}
              alt={sectionData.weddingWebsite.uiAlt}
              {...featureImageProps}
              aspectratio={weddingWebsiteUiAspectRatio}
              scale={1.28}
              style={{ width: '100%', top: '14%', left: '55%' }}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-wedding-website/router-ww-couple.png'}
              alt={sectionData.weddingWebsite.foregroundAlt}
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={registryFeatureWrapperRef}>
            <RegistryFeatureBackgroundImage
              src={'home/router-sections/router-registry/registry-background.png'}
              alt=""
              {...featureImageProps}
              aspectratio={registryBgAspectRatio}
              style={{ transformOrigin: 'center top' }}
              isEagerLoading={true}
              scale={1.07}
            />
            <RegistryFeatureUiImage
              src={'home/router-sections/router-registry/registry-products.png'}
              alt={sectionData.registry.uiAlt}
              {...featureImageProps}
              scale={0.1}
              aspectratio={registryUiAspectRatio}
              style={{ width: '100%', height: 'auto', opacity: 1, transformOrigin: 'center bottom' }}
            />
            <RegistryFeatureForegroundImage
              src={'home/router-sections/router-registry/registry-couple.png'}
              alt={sectionData.registry.foregroundAlt}
              {...featureImageProps}
              scale={1.3}
              aspectratio={registryFgAspectRatio}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={hotelRoomblocksFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-travel/bedroom-v2.webp'}
              alt={sectionData.hotelRoomBlocks.backgrounAlt}
              {...featureImageProps}
              aspectratio={hotelBgAspectRatio}
              isEagerLoading={true}
              scale={1}
              style={{ top: '51%', left: '50%' }}
            />
            <FeatureUiImage
              src={'home/router-sections/router-travel/hotels.webp'}
              alt={sectionData.hotelRoomBlocks.uiAlt}
              {...featureImageProps}
              aspectratio={hotelUiAspectRatio}
              scale={1.63}
              style={{ width: '100%', height: 'auto', opacity: 0.9, top: '14%', left: '50%' }}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-travel/couple.webp'}
              alt={sectionData.hotelRoomBlocks.foregroundAlt}
              {...featureImageProps}
              aspectratio={hotelFgAspectRatio}
              style={{ transform: 'translate(-50%, 0%)', top: '0%' }}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={guestListFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-guest-list/router-gl-bg.jpg'}
              alt=""
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureUiImage
              src={'home/router-sections/router-guest-list/router-gl-ui-bg.png'}
              alt={sectionData.guestList.uiAlt}
              {...featureImageProps}
              aspectratio={guestListUiAspectRatio}
              style={{ opacity: 1, transformOrigin: 'center 70%' }}
            />
            <FeatureUiImage
              src={'home/router-sections/router-guest-list/router-gl-ui.png'}
              alt={sectionData.guestList.uiAlt}
              {...featureImageProps}
              aspectratio={guestListUiAspectRatio}
              style={{ opacity: 1, transformOrigin: 'center 70%' }}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-guest-list/router-gl-couple.png'}
              alt={sectionData.guestList.foregroundAlt}
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={saveTheDatesFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-save-the-dates/router-std-bg.jpg'}
              alt=""
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-save-the-dates/router-std-couple.png'}
              alt={sectionData.saveTheDates.foregroundAlt}
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
            />
            <FeatureUiImage
              src={'home/router-sections/router-save-the-dates/router-std-cards.png'}
              alt={sectionData.saveTheDates.uiAlt}
              scale={1.08}
              {...featureImageProps}
              aspectratio={saveTheDateUiAspectRatio}
              style={{ width: '100%', top: 0 }}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={invitationsFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-invites/invites-background-v3.jpg'}
              alt=""
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureUiImage
              src={'home/router-sections/router-invites/invites-cards-v4.png'}
              alt={sectionData.invitations.uiAlt}
              scale={1.6}
              {...featureImageProps}
              aspectratio={guestListUiAspectRatio}
              style={{ opacity: 0.2, transformOrigin: 'center 80%' }}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-invites/invites-couple-v3.png'}
              alt={sectionData.invitations.foregroundAlt}
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={smartRSVPFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-inv-rsvp/router-inv-rsvp-bg.jpg'}
              alt=""
              {...featureImageProps}
              scale={1.2}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureUiImage
              src={'home/router-sections/router-inv-rsvp/router-inv-rsvp-card.jpg'}
              alt={sectionData.smartRSVP.uiAlt}
              scale={0.25}
              {...featureImageProps}
              aspectratio={smartRSVPUiAspectRatio}
              style={{ width: '100%', bottom: '2%', top: 'unset', left: '50%', opacity: 0.5, transformOrigin: 'center bottom' }}
            />
            <FeatureUiImage
              src={'home/router-sections/router-inv-rsvp/router-inv-rsvp-ui-bubbles.png'}
              alt={sectionData.smartRSVP.uiAlt}
              scale={0.75}
              {...featureImageProps}
              aspectratio={smartRSVPUiAspectRatio}
              style={{ width: '100%', top: '10%', transformOrigin: 'center top' }}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-inv-rsvp/router-inv-rsvp-couple.png'}
              alt={sectionData.invitations.foregroundAlt}
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              style={{ bottom: 0, top: 'unset', transform: 'translate(-50%,0) scale(0.35)' }}
            />
          </FeatureAnimationContainer>
          <FeatureAnimationContainer selectedFeatureIndex={selectedFeatureIndex} ref={mobileAppFeatureWrapperRef}>
            <FeatureBackgroundImage
              src={'home/router-sections/router-mobile/router-mobile-bg.jpg'}
              alt=""
              {...featureImageProps}
              aspectratio={featureBgAspectRatio}
              isEagerLoading={true}
            />
            <FeatureForegroundImage
              src={'home/router-sections/router-mobile/router-mobile-couple.png'}
              alt={sectionData.guestApp.foregroundAlt}
              {...featureImageProps}
              aspectratio={mobileAppUiAspectRatio}
              style={{
                top: '16%',
                width: '100%',
                transformOrigin: 'center 35%',
                transform: 'translate(-50%, 0)',
              }}
            />
            <FeatureUiImage
              src={'home/router-sections/router-mobile/router-mobile-phone_v2.svg'}
              alt=""
              {...featureImageProps}
              aspectratio={mobileAppUiAspectRatio}
              style={{
                width: '100%',
                opacity: 0,
                transformOrigin: 'center 35%',
                top: '17%',
                transform: 'translate(-50%, 0)',
              }}
            />
          </FeatureAnimationContainer>
        </Box>
        <StyledColumns verticalAlign={'center'}>
          <FeaturesColumn width={5}>
            <FeaturesList isControlVersion={false}>
              {featureItems.map((featureItem, index) => {
                const isActive = selectedFeatureIndex === index;
                return (
                  <FeatureItem key={featureItem.id} id={featureItem.id} href={featureItem.to} $isActive={isActive}>
                    <FeatureItemWrapper>
                      <LinkTitle>
                        {featureItem.label} &nbsp;
                        {isActive && <JoykitIcon name="rightArrow" color="linkHomeFeatureHover" />}
                      </LinkTitle>
                      {isActive && (
                        <LinkDescription variant="featureCopy17" color="yellow6" tagName="span" textAlign="left">
                          {featureItem.desc}
                        </LinkDescription>
                      )}
                    </FeatureItemWrapper>
                  </FeatureItem>
                );
              })}
            </FeaturesList>
          </FeaturesColumn>
        </StyledColumns>
        <PhotoCreditContent color="linkHomeFeature" id="photoCreditHomeProductFeature" />
      </ContentWrapper>
    </StyledSection>
  );
};
