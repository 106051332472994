export const SECTION_ID = 'Home_Room_Block_Section';

export const BACKGROUND_IMAGE = {
  mobile: 'home/room-blocks/banner_roomblocks_mobile_v2.jpg',
  desktop: 'home/room-blocks/banner_roomblocks_desktop_v2.jpg',
};

export const HEIGHT = '39.4vw';
export const HEIGHT_MOBILE = '84vh';
export const HEIGHT_MOBILE_MIN = '750px';
export const HEIGHT_TABLET = '125vh';

export const WIDTH_TITLE = '346px';
export const WIDTH_TITLE_MOBILE = '255px';
export const WIDTH_TITLE_XL = '420px';
export const WIDTH_BODY = '395px';
export const WIDTH_BODY_MOBILE = '313px';
export const WIDTH_BODY_XL = '480px';
